import React from 'react';
import ReactDOM from 'react-dom/client';
import {HelmetProvider} from 'react-helmet-async';
import Root, {RootFallback} from "./routes/Root/Root";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import TestPage from "./routes/TestPage/TestPage";
import {FormLayout} from "./layouts/FormLayout";
import ErrorBoundary from "./routes/Error/ErrorBoundary";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <RootFallback />,
        children: [
            {
                element: <FormLayout />,
                errorElement : <ErrorBoundary />,
                children: [
                    {
                        index: true,
                        element: <TestPage />,
                        errorElement: <ErrorBoundary />,
                    },
                ],
            },
        ],
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        {/*<React.StrictMode>*/}
            <HelmetProvider>
                <RouterProvider router={router} />
            </HelmetProvider>
        {/*</React.StrictMode>*/}
    </>
);