import styled from 'styled-components';
import {Link, useLocation, useRouteError} from 'react-router-dom';
import {Heading1} from '../../components/Heading/Heading';

const StyledErrorBoundary = styled.div``;

const ErrorBoundary = () => {
    const error = useRouteError();
    const location = useLocation();

    console.log("Error in ErrorBoundary: ", error);

    return (
        <StyledErrorBoundary>
            <Heading1>Something went wrong</Heading1>
            <Link to={location?.pathname ?? "/"} reloadDocument>Probeer het opnieuw</Link>
        </StyledErrorBoundary>
    );
}

export default ErrorBoundary;