import {Heading3} from '../../components/Heading/Heading';
import {Text} from '../../components/Text/Text';
import {Container} from '../../components/Utils/Utils';

const TestPage = () => {
    return (
        <>
            <Container>
                <Heading3>Register for the Winter OWL 2025</Heading3>
                <Text>Lorem ipsum dolor sit amet</Text>
            </Container>
        </>
    );
}

export default TestPage;